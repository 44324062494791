<template>
    <admin-dashboard-layout>
        <template v-slot:left-tools>
            <timeframe-select/>
        </template>
        <template v-slot:right-tools>
            <v-btn @click="$router.push({name: 'admin.industries.create'})" v-text="'Create'"/>
        </template>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="industries"
            :items-per-page="5"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | formattedDate }}
            </template>
            <template v-slot:item.actions="{ item }">
                <delete-dialog :model="item" type="industries" @success="init" />
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import Industry from "@/models/Industry";
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import DeleteDialog from "@/components/dialogs/DeleteDialog";
import TimeframeSelect from "@/views/partials/TimeframeSelect";

export default {
    name: "Index",
    components: {TimeframeSelect, DeleteDialog, AdminDashboardLayout},
    data: function () {
        return {
            loading: false,
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: 'Name', value: 'name' },
                { text: 'Created at', value: 'created_at' },
                { text: 'Actions', value: 'actions' },
            ],
            industries: [],
        }
    },
    methods: {
        handleClickRow(industry) {
            this.$router.push({name: 'admin.industries.show', params: {id: industry.id}})
        },
        async init() {
            this.loading = true
            await this.loadTable()
            this.loading = false
        },
        async loadTable() {
            let query = Industry;

            if (this.$route.query.lastDays) {
                query = query.where('lastDays', this.$route.query.lastDays);
            }

            this.industries = await query.get()
        }
    },
    mounted() {
       this.init()
    },
    watch: {
        "$route.query": async function (val, oldVal) {
            if (val !== oldVal) {
                await this.init()
            }
        }
    }
}
</script>

<style scoped>

</style>
